
import React, { Fragment, useState } from 'react';

import {
    Wrapper, Container, Bar, ThumbContainer, Thumb,
} from './Switch.style';


const Switch = ({ enabled, onToggle }) => {
    return (
        <Wrapper onClick={() => onToggle(!enabled)}>
            <Container>
                <Bar enabled={enabled}></Bar>
                <ThumbContainer enabled={enabled}>
                    <Thumb enabled={enabled}></Thumb>
                </ThumbContainer>
            </Container>
        </Wrapper>
    );
}

export default Switch;


