/* eslint-disable react/sort-comp */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router';
import { parse, stringify } from 'query-string';
import Error from 'components/Error';
import preloadSaga from './sagas';
import * as actions from './actions';
import * as selectors from './selectors';

type Props = {
    match: Object,
    load: Function,
    lazyload: Function,
    location: Object,
    staticContext: Object,
    preloaded: boolean,
    /**
     * error status code
     */
    error: number,
    /**
     * a path to redirect to
     */
    redirect: string,
};

type Options = {
    /**
     * If this route should be protected
     * Requires that selector and redirectTo are set.
     */
    isProtectedRoute: boolean,
    /**
     * An array of objects which determine if the user is authorised to
     * view the path, with redirects in case they are not.
     */
    checkAuthorised: Array<{
        /**
         * A selector which accepts the full state
         */
        selector: (state: any) => any,
        /**
         * Takes the value from the selector, evaluates it and returns
         * a path to redirect to, or undefined to not redirect
         */
        redirect: (val: any) => string,
    }>,
};

const defaultOptions: Options = {
    isProtectedRoute: false,

    // Some pages might want to re-run their `load` each time in order to not show stale/incorrect content
    // (blog posts for example).
    // In this case the blog post `load` saga will check the store before making a request which avoids
    // double-load issues
    forceLoad: false,
};

const asPage = (WrappedComponent, pageId, usePreload = false, useLoad = false, options: Options = defaultOptions) => {
    class Page extends React.PureComponent<Props> {
        static getPreloadSaga() {
            return function* preload(location) {
                if (usePreload) {
                    yield preloadSaga(location, pageId);
                }
            };
        }

        componentDidMount() {
            if (options.forceLoad || (useLoad && !this.props.preloaded)) {
                this.props.load(this.props.match.params, this.props.location.search, this.props.location.pathname);
            }

            // Call on client side after component was mounted
            // Good to load content that shouldn't load for SEO/search engine indexing/...
            this.props.lazyload(
                this.props.match.params,
                this.props.location.search,
                this.props.location.pathname,
                this.props.preloaded,
            );
        }

        componentDidUpdate(prevProps) {
            if (
                useLoad &&
                (JSON.stringify(this.props.match.params) !== JSON.stringify(prevProps.match.params) ||
                    this.props.location.search !== this.props.location.search)
            ) {
                this.props.load(this.props.match.params, this.props.location.search, this.props.location.pathname);

                // Call on client side after component was mounted
                // Good to load content that shouldn't load for SEO/search engine indexing/...
                this.props.lazyload(this.props.match.params, this.props.location.search, this.props.location.pathname);
            }
        }

        render() {
            const { redirect, ...props } = this.props;

            if (redirect && redirect !== props.location.pathname) {
                // add the 'next' query param to allow redirecting to the requested url after authenticating
                const redirectQueryParam = this.props.location && stringify({ next: this.props.location.pathname });
                return <Redirect to={`${redirect}?${redirectQueryParam}`} />;
            }

            if (this.props.error) {
                if (this.props.staticContext) {
                    this.props.staticContext.statusCode = this.props.error;
                }
                return <Error statusCode={this.props.error} />;
            }

            return <WrappedComponent {...props} />;
        }
    }

    /**
     * Decode nested location data
     * @param search
     */
    const parseSearch = (search) => {
        const parsedSearch = parse(search, { arrayFormat: 'index' });
        if (parsedSearch.location) {
            parsedSearch.location = JSON.parse(parsedSearch.location);
        }

        return parsedSearch;
    };

    const mapStateToProps = (state) => {
        let redirectPath;

        // We set the redirectPath after evaluating the selectors
        // The first selector that evaluates to a path is used
        if (Array.isArray(options.checkAuthorised)) {
            options.checkAuthorised.some(({ selector, redirect }) => {
                redirectPath = redirect(selector(state));
                if (redirectPath) {
                    return true;
                }
                return false;
            });
        }

        return {
            preloaded: selectors.getPreloaded(state, pageId),
            error: selectors.getError(state, pageId),
            redirect: redirectPath,
        };
    };

    const mapDispatchToProps = dispatch => ({
        load: (params, search, pathname) => {
            const parsedSearch = parseSearch(search);
            dispatch(actions.load.create(pageId, params, parsedSearch, pathname));
        },
        lazyload: (params, search, pathname, preloaded) => {
            const parsedSearch = parseSearch(search);
            dispatch(actions.lazyload.create(pageId, params, parsedSearch, pathname, preloaded));
        },
    });

    return withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps,
        )(Page),
    );
};

export default asPage;
