import styled from 'styled-components';

import { a } from 'react-spring';

const Wrapper = styled.div`
    padding: 24px 24px 0;
    margin-bottom: 40px;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,.30), 0 2px 6px 2px rgba(60,64,67,.15);
`;

const SwitchLabel = styled.span`
    font-size: 13px;
    color: rgba(0,0,0,0.54);
    max-width: 100%;
`;

const ThumbsContainer = styled.aside`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;
`;

const Thumb = styled(a.div)`
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    // width: 100px;
    // height: 100px;
    padding: 4px;
    box-sizing: border-box;
    opacity: 0;

    width: 100%;
    height: 100%;
`;

const ThumbInner = styled.div`
    display: flex;
    min-width: 0px;
    overflow: hidden;

    width: 100%;
    height: 100%;
`;

const Img = styled.img`
    display: block;
    // width: auto;
    // height: 100%;

    width: 100%;
    height: 100%;
`;

export {
    Wrapper, SwitchLabel,
    ThumbsContainer, Thumb, ThumbInner, Img,
};
