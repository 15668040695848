// @flow
/* eslint-disable import/prefer-default-export */
import { routes } from 'utils/routes';

import { type LinkItemType } from './Error.flow';

export const ERROR_CODES = {
    ERROR_401: 401,
    ERROR_403: 403,
    ERROR_404: 404,
    ERROR_500: 500,
};

export const AVAILABLE_LINKS: { [string]: Array<LinkItemType> } = {
    [ERROR_CODES.ERROR_403]: [],
    [ERROR_CODES.ERROR_404]: [
        {
            i18nKey: 'navigation_home',
            url: '/',
        },
        {
            i18nKey: 'navigation_login',
            url: routes.login(),
        },
    ],
    [ERROR_CODES.ERROR_401]: [
        {
            i18nKey: 'navigation_login',
            url: routes.login(),
        },
    ],
    [ERROR_CODES.ERROR_500]: [
        {
            i18nKey: 'navigation_home',
            url: '/',
        },
    ],
};
