import styled from 'styled-components';
import { IconAddCircle } from 'components/icons';


const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
`;

const AddIcon = styled(IconAddCircle)`
    color:  #1aa358;
    margin: 0 16px 6px 0;

    &:hover {
        color: #1a8358;
    }
`;

export {
    Wrapper, AddIcon,
};
