import styled from 'styled-components';


const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`;

const Container = styled.div`
    @keyframes slide-in-blurred-top {
        0% {
            transform: translateY(-300px);
            transform-origin: 50% 0%;
            filter: blur(40px);
            opacity: 0;
        }
        100% {
            transform: translateY(0);
            transform-origin: 50% 50%;
            filter: blur(0);
            opacity: 1;
        }
    }

    padding: 20px;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    box-shadow: 2px 2px 7px 1px rgba(0,0,0,0.25);
    max-width: 400px;
    max-height: 400px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    animation: slide-in-blurred-top 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
`;

const Title = styled.h3`
`;

const Text = styled.span`
`;

const Button = styled.button`
    color: #fff !important;
    text-transform: uppercase;
    text-decoration: none;
    background: #51bfe0;
    padding: 16px 64px;
    border-radius: 5px;
    display: inline-block;
    border: none;
    transition: all 0.4s ease 0s;
    cursor: pointer;

    &:hover {
        background: #51bfe0;
        letter-spacing: 3px;
        box-shadow: 2px 2px 11px -1px rgba(0,0,0,0.57);
        transition: all 0.4s ease 0s;
    }
`;

const SignIn = styled(Button)`
`;

const Logout = styled(Button)`
    background: #744775;
    &:hover {
        background: #744775;
    }
`;

const LinkWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
    width: 100%;
`;

export {
    Wrapper, Container,
    Title, Text,
    SignIn, Logout,
    LinkWrapper,
};
