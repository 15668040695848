import styled from 'styled-components';

const Wrapper = styled.div`
    margin-right: 0;
    max-width: 100%;
    margin: 16px 0;
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    height: 30px;
    line-height: 28px;
    align-items: center;
    display: flex;
    margin-left: 12px;
`;

const Container = styled.div`
    cursor: grab;
    width: 36px;
    height: 24px;
    position: relative;
    margin-right: 8px;
    float: left;
    touch-action: pan-x;
`;

const Bar = styled.div`
    transition-delay: .05s;
    transition: all .08s linear;
    transition-property: transform,background-color,-webkit-transform;
    background-color: rgb(158,158,158);
    left: 1px;
    width: 34px;
    top: 5px;
    height: 14px;
    border-radius: 8px;
    position: absolute;

    ${({ enabled }) => enabled && `background-color: rgba(26,115,232,0.5);`}
`;

const ThumbContainer = styled.div`
    transition: all .08s linear;
    transition-property: transform,background-color,-webkit-transform;
    top: 2px;
    left: 0;
    width: 16px;
    position: absolute;
    transform: translate3d(0,0,0);
    z-index: 1;

    transform: ${({ enabled }) => enabled ? 'translate3d(100%,0,0)' : 'translate3d(0,0,0)'}
`;

const Thumb = styled.div`
    transition-delay: .05s;
    transition: all .08s linear;
    transition-property: transform,background-color,-webkit-transform;
    background-color: rgb(250,250,250);
    position: absolute;
    margin: 0;
    left: 0;
    top: 0;
    outline: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12);

    ${({ enabled }) => enabled && `background-color: rgba(26,115,232);`}

    &::before {
        background-color: transparent;
        border-radius: 50%;
        content: '';
        position: absolute;
        display: block;
        height: auto;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        transition: all .5s;
        width: auto;
    }
`;

export {
    Wrapper, Container, Bar, ThumbContainer, Thumb,
};
