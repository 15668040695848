import { compile } from 'path-to-regexp';

import paths from '../route-paths';

// Takes some route paths and compiles them to regex
const reverse = (routePaths, compiledRoutePaths = {}) => {
    Object.keys(routePaths).forEach((name) => {
        if (typeof routePaths[name] === 'string') {
            compiledRoutePaths[name] = compile(routePaths[name], { encode: encodeURIComponent });
        }
        else {
            compiledRoutePaths[name] = reverse(routePaths[name]);
        }
    });

    return compiledRoutePaths;
};

// Compile all the paths e.g. can do routes.search.listings({id: '123'})
const routes = reverse(paths);

export { reverse, routes };
