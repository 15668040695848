// @flow
import type { ActionT, AppT } from '../types';
import { getAction, ActionTypes } from '../actions';

export const initialState: AppT = Object.freeze({
    locale: 'en-GB',
});

export default (state: AppT = initialState, action: ActionT): AppT => {
    const { type, data } = action;

    switch (type) {
        case getAction('LOCALE', ActionTypes.SET): {
            return {
                ...state,
                locale: data || {},
            };
        }
    }

    return state;
};
