
import styled from 'styled-components';


const InputLabel = styled.label`
    color: rgba(0,0,0,.54);
    display: block;
    font-size: 13px;
    padding-bottom: 8px;
`;

const InputField = styled.input`
    background-color: #fff;
    border: 0;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: 0 0 0 2px transparent inset, 0 0 0 1px #e0e0e0 inset;
    color: rgba(0,0,0,.87);
    font-family: Roboto,"Helvetica Neue",sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    max-height: 36px;
    max-width: 100%;
    outline: 0;
    padding: 8px!important;
    transition: box-shadow .15s;
    vertical-align: middle;
    width: 100%;
`;

export { InputLabel, InputField };
