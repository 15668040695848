export default {
    teal: {
        700: {
            hex: '#20847E',
        },
        500: {
            hex: '#29A89C',
        },
        400: {
            hex: '#80CCC2',
        },
        100: {
            hex: '#eef8f7',
        },
    },
    red: {
        500: {
            hex: '#b00020',
        },
        200: {
            hex: '#f9e2e5',
        },
    },
    green: {
        500: {
            hex: '#4ab52d',
        },
        200: {
            hex: '#d5e8d1',
        },
    },
    blue: {
        500: {
            hex: '#2cb0dc',
        },
        200: {
            hex: '#d4e1e5',
        },
    },
    grey: {
        700: {
            hex: '#333333',
        },
        600: {
            hex: '#444444',
        },
        500: {
            hex: '#808080',
        },
        400: {
            hex: '#BBBBBB',
        },
        300: {
            hex: '#E6E6E6',
        },
        200: {
            hex: '#F5F5F5',
        },
        100: {
            hex: '#FAFAFA',
        },
    },
    pink: {
        500: {
            hex: '#D1337A',
        },
    },
    white: {
        hex: '#FFFFFF',
    },
    black: {
        hex: '#000000',
    },
    yellow: {
        500: {
            hex: '#FFB30F',
        },
        200: {
            hex: '#fff8e2',
        },
    },
};
