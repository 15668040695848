
import React, { Fragment, useState } from 'react';

import {
    Table, THead, THeadTH,
} from './Pages.style';

import Page from '../Page';

// import Icon from 'components/Icon';
// import { IconApartment } from 'components/icons';
// https://material-ui.com/components/icons/

import { Wrapper, Container } from '../Common/Layout.style';

const Pages = ({ pages, emptyPages, pagesCount, addNewCback, onDeleteCback }) => {
    return (
        <Wrapper>
            <Container>
                <Table>
                    <colgroup>
                        <col width="50%"></col>
                        <col width="50%"></col>
                        <col width="85"></col>
                    </colgroup>
                    <THead>
                        <tr>
                            <THeadTH>Page</THeadTH>
                            <THeadTH>Status</THeadTH>
                            <THeadTH>&nbsp;</THeadTH>
                        </tr>
                    </THead>
                    {pages.map(page => (
                        <Page
                            key={page.id}
                            page={page}
                            pagesCount={pagesCount}
                            addNewCback={addNewCback}
                            onDeleteCback={onDeleteCback}
                        />
                    ))}
                    {emptyPages.map(page => (
                        <Page
                            key={page.id}
                            page={page}
                            pagesCount={pagesCount}
                            addNewCback={addNewCback}
                            onDeleteCback={onDeleteCback}
                        />
                    ))}
                </Table>
            </Container>
        </Wrapper>
    );
}

export default Pages;
