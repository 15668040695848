// @flow
/* global $Values */
import React from 'react';
import LinkStyled from './Link.style';
import LinkVariant from './Link.constants';

type Props = {
    /**
     * Content to be inserted inside link.
     */
    children: React.Node | string,
    /**
     * React router to - a string representing the location to link to.
     * https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/api/Link.md
     */
    to: string,
    /**
     * Variant to apply styling to link.
     */
    variant?: $Values<typeof LinkVariant>,
    className: string,
    onClick?: Function,
};

const Link = ({ to, variant, children, className, onClick }: Props) => (
    <LinkStyled to={to} variant={variant} className={className} onClick={onClick}>
        {children}
    </LinkStyled>
);

Link.Variant = {
    ...LinkVariant,
};

Link.defaultProps = {
    variant: Link.Variant.DEFAULT,
};

export default Link;
