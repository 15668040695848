const config = {
    all: {
        env: process.env.NODE_ENV || 'development',
        mode: process.env.MODE || 'development',
        basename: process.env.PUBLIC_PATH || '',
        host: process.env.HOST || 'localhost',
        port: process.env.PORT || 8100,
        staticPath: process.env.STATIC_PATH || 'static',
    },
    test: {},
    development: {},
    production: {},
};

export default { ...config.all, ...config[config.all.env] };