import { createGlobalStyle } from 'styled-components';
import sanitizedStyles from './sanitize';
import typography from './typography';


const GlobalStyle = createGlobalStyle`
    // body {
    //     color: ${props => (props.whiteColor ? 'white' : 'black')};
    //     font-family: ${props => props.theme.fontFamily};
    // }

    // Test
    * {
        box-sizing: border-box;
    }
      
    html,
    body {
        overscroll-behavior-y: contain;
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;

        // Only on homepage
        // position: fixed;
        // opacity: 0.4;
    }

    body {
        font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial,
          sans-serif;
        background: transparent;
        -webkit-touch-callout: none;
        user-select: none;
    }
    // Test - END

    body *, html * {
        -webkit-font-smoothing: antialiased;
    }

    /* eslint-disable no-unused-expressions */
    ${sanitizedStyles}
    ${typography}

    fieldset {
        border: 0;
        padding: 0;
    }

    p {
        white-space: pre-line;
    }
`

export default GlobalStyle;
