
import React, { Fragment, useState } from 'react';

import {
    Wrapper, AddIcon,
} from './Facts.style';


import { CancelButton, SaveButton } from '../Common/Button.style';
import { InputLabel, InputField } from '../Common/Input.style';
import { ActionsList } from '../Common/Lists.style';
import { ContentRow } from '../Common/Row.style';

import Fact from '../Fact';


const Facts = ({ facts, addNewFact, onDeleteFact, onChangeFact }) => {

    return (
        <Wrapper>
            {facts.map((fact, i) => (
                <Fact
                    key={`fact-${i}`}
                    index={i}
                    {...fact}
                    onChange={onChangeFact}
                    onDelete={onDeleteFact}
                />
            ))}
            <AddIcon fontSize="large" onClick={addNewFact} />
        </Wrapper>
    );
}

export default Facts;
