import styled from 'styled-components';


const Table = styled.table`
    table-layout: fixed;
    border-spacing: 0;
    border-collapse: separate;
    width: 100%;
    text-align: left;
`;

const THead = styled.thead`
    th:first-child {
        border-top-left-radius: 8px;
    }

    th:last-child {
        border-top-right-radius: 8px;
    }
`;

const THeadTH = styled.th`
    color: rgba(0,0,0,0.64);
    font-size: 12px;
    background-color: var(--fire-color-table-header-bg);
    border-bottom: 1px solid rgba(0,0,0,0.12);
    padding: 8px 24px;
    font-weight: 500;
    text-align: left;
`;

export {
    Table, THead, THeadTH,
};
