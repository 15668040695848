import {
    h1LargeStyles,
    h1Styles,
    h1SmallStyles,
    h2Styles,
    h2VariantStyles,
    h3Styles,
    h3VariantStyles,
    h4Styles,
    pStyles,
    spanStyles,
    labelStyles,
    blockquoteStyles,
    focalSmallStyles,
    smallTextStyles,
    reallySmallTextStyles,
    aStyles,
    H1,
    H2,
    H3,
    H4,
    P,
    SmallText,
    ReallySmallText,
    SecondaryText,
    Span,
    Label,
    Blockquote,
    A,
    Link,
    Heading2,
    Heading3,
} from './Typography.style';

export {
    h1LargeStyles,
    h1Styles,
    h1SmallStyles,
    h2Styles,
    h2VariantStyles,
    h3Styles,
    h3VariantStyles,
    h4Styles,
    pStyles,
    spanStyles,
    labelStyles,
    blockquoteStyles,
    focalSmallStyles,
    smallTextStyles,
    reallySmallTextStyles,
    aStyles,
    H1,
    H2,
    H3,
    H4,
    P,
    SmallText,
    ReallySmallText,
    SecondaryText,
    Span,
    Label,
    Blockquote,
    A,
    Link,
    Heading2,
    Heading3,
};
