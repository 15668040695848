import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';

import { connect } from 'react-redux';
import asPage from 'components/Page';

import { useSelector } from 'react-redux'
import {
    useFirebase, isLoaded, isEmpty, useFirestoreConnect,
    useFirestore, firestoreConnect, populate,
} from 'react-redux-firebase';
import 'firebase/firestore';
import {
    BrowserRouter,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';

import PagesList from './components/Pages';

import { AddButton } from './components/Common/Button.style';
import { ActionsList } from './components/Common/Lists.style';
import { ContentRow } from './components/Common/Row.style';
import { Wrapper, Container } from './components/Common/Layout.style';


const Pages = ({ pages }) => {
    const [emptyPages, setNewEmptyPages] = useState([]);

    const firebase = useFirebase();
    const firebaseAppAuth = firebase.auth();

    const auth = useSelector(state => state.firebase.auth)

    if (!isLoaded(auth)) {
        return <div>loading...</div>;
    }

    if (isEmpty(auth)) {
        return (
            <Redirect push to={'/'} />
        )
    }

    const hasPages = pages.length > 0 || emptyPages.length > 0;

    return (
        <div>
            {hasPages && (
                <PagesList
                    pages={pages}
                    emptyPages={emptyPages}
                    pagesCount={pages.length}
                    onDeleteCback={(page) => {
                        const tmpEmptyPages = emptyPages.filter(p => p.id != page.id);
                        setNewEmptyPages(tmpEmptyPages);
                    }}
                    addNewCback={(page) => {
                        const tmpEmptyPages = emptyPages.filter(p => p.id != page.id);
                        setNewEmptyPages(tmpEmptyPages);
                    }}
                />
            )}

            <Wrapper noMargin>
                <Container noShadow>
                    <ActionsList>
                        <AddButton
                            type="button"
                            onClick={() => setNewEmptyPages([
                                ...emptyPages,
                                {
                                    id: '_' + Math.random().toString(36).substr(2, 9),
                                    isNew: true,
                                    order: null,
                                },
                            ])}
                        >
                            Add New Page
                        </AddButton>
                    </ActionsList>
                </Container>
            </Wrapper>
        </div>
    );
};

const mapStateToProps = state => ({
    // problems: getProblems(state),
    pages: state.firestore.ordered.pages || [],
});

// export default asPage(connect(mapStateToProps, null)(Pages), 'Pages', true);
export default firestoreConnect(() => [{
	collection: 'pages',
	// orderBy: [
	// 	[ 'order', 'asc' ],
	// ],
	// where: [
	// 	[ "chainId", "==", props.chainId ],
	// ],
}])(asPage(connect(mapStateToProps, null)(Pages), 'Pages', true, true));

