import { fork, call, all } from 'redux-saga/effects';

import pagesSaga from '../pages/sagas';
import userSagas from './user';

export default function* rootSaga() {
    yield all([fork(pagesSaga), fork(userSagas)]);
}

const waitAll = sagasWithArgsToWaitFor =>
    function* waitAllGenerator() {
        for (let i = 0; i < sagasWithArgsToWaitFor.length; i += 1) {
            const { saga, args } = sagasWithArgsToWaitFor[i];
            yield call(saga, args);
        }
    };

// const waitAll = sagasWithArgsToWaitFor =>
//     function* waitAllGenerator() {
//         yield all(sagasWithArgsToWaitFor.map(({ saga, args }) => call(saga, args)));
//     };

export { waitAll };
