import styled from 'styled-components';


const Button = styled.button`
    align-items: center;
    background-color: rgb(250,250,250);
    border: 0;
    border-radius: 8px;
    color: rgb(33,33,33);
    cursor: pointer;
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    font-style: inherit;
    font-variant: inherit;
    font-family: Roboto,"Helvetica Neue",sans-serif;
    text-decoration: none;
    letter-spacing: .25px;
    line-height: 36px;
    margin: 0 0 0 8px;
    min-height: 36px;
    min-width: 60px;
    outline: none;
    overflow: hidden;
    padding: 0 16px;
    position: relative;
    text-align: center;
    text-transform: none;
    transition: box-shadow .2s ease,background-color .2s ease;
    vertical-align: middle;
    white-space: nowrap;
`;

const CancelButton = styled(Button)`
    color: rgba(0,0,0,.54);

    &:hover {
        background: rgba(0,0,0,.06);
    }
`;

const SaveButton = styled(Button)`
    background-color: #1a73e8;
    box-shadow: none;
    color: #fff;

    &:hover {
        background-color: #1765cc;
        box-shadow: none;
    }
`;

const AddButton = styled(Button)`
    background-color: #1aa358;
    box-shadow: none;
    color: #fff;

    &:hover {
        background-color: #1a8358;
        box-shadow: none;
    }
`;


export { Button, CancelButton, SaveButton, AddButton };
