
import styled, { css } from 'styled-components';


const firstColumnStyle = css`
    padding-bottom: 0px;
    max-width: 100%;
`;

const rightAlignStyle = css`
    display: flex;
    justify-content: flex-end;
    flex-direction: row;  
`;

const centerAlignStyle = css`
    display: flex;
    align-items: center;
    align-content: center;
`;

const ContentRow = styled.div`
    min-width: 0;
    padding-bottom: 40px;

    margin: ${({ noMargin }) => noMargin ? '0' : '0 15%'};
    ${({ first }) => first && firstColumnStyle}
    ${({ right }) => right && rightAlignStyle}
    ${({ center }) => center && centerAlignStyle}
`;

export { ContentRow };
