import styled from 'styled-components';
import { IconDelete, IconVerticalSplit, IconArrowUpward, IconArrowDownward} from 'components/icons';
import { mix } from 'polished';


const TBody = styled.tbody`
`;

const TBodyTR = styled.tr`
    cursor: pointer;
    background: ${({ background }) => background};

    &:hover {
        background: ${({ background }) => mix(0.1, '#999', background)};

        & > td:last-child > div {
            display: flex;
            justify-content: flex-end;
        }
    }
`;

const TBodyTD = styled.td`
    color: rgba(0,0,0,0.54);
    font-size: 13px;
    line-height: 16px;
    height: 48px;
    padding: 0;
    border-bottom: 1px solid rgba(0,0,0,0.06);
    white-space: nowrap;
`;

const TBodyTDActions = styled(TBodyTD)`
    width: 85px;
    overflow: visible;

    & > div {
        display: none;

        ${({ isVisible }) => isVisible && `
            display: flex;
            justify-content: flex-end;
        `}
    }
`;

const CellWrapper = styled.div`
    width: inherit;
    position: relative;
    z-index: 4;
    padding: 10px 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const ActionsCellWrapper = styled.div`
    width: inherit;
    position: relative;
    z-index: 4; 
    padding: 10px 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const LayoutRow = styled.div`
    flex-direction: row;
    display: flex;
    lign-items: center;
    align-content: center;
    max-width: 100%;
    justify-content: flex-start;
`;

const EnabledLabel = styled.span`
    color: ${({ enabled }) => enabled ? '#00bfa5' : 'rgba(0,0,0,0.54)'};
`;

const DetailContentTR = styled.tr`
    display: table-row;
    height: 100px;
`;

const DetailContentTD = styled(TBodyTD)`
    width: inherit;
    position: relative;
    border-bottom: 0!important;
`;

const DetailsContentWrapper = styled.div`
    padding-bottom: 24px!important;
    width: inherit;
    position: relative;
    z-index: 4;
    padding: 10px 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 3;
    border-top: 1px solid rgba(0,0,0,0.12);

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        padding: 10px 24px;
    }
`;

const DetailsContentCard = styled.div`
    top: -49px!important;
    position: absolute;
    background-color: white;
    top: -64px;
    left: -16px;
    right: -16px;
    bottom: 0;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,.30), 0 2px 6px 2px rgba(60,64,67,.15);
    border-radius: 8px;
    z-index: 2;
`;

const EditCard = styled.div`
    display: block;
    height: auto!important;
    white-space: normal!important;
    position: relative;
`;

const Form = styled.form`
    display: block;
    margin-top: 0em;
`;

const FieldSet = styled.fieldset`
    border: 0;
    margin: 0;
    min-width: 0;
    padding: 0;
    flex-direction: column;
    display: flex;
`;

const SwitchLabel = styled.span`
    font-size: 13px;
    color: rgba(0,0,0,0.54);
    max-width: 100%;
`;


const IconContainer = styled.div`
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
`;


const EditIcon = styled(IconVerticalSplit)`
    color: rgba(0,0,0,0.54);

    &:hover {
        color: #00bfa5;
    }
`;

const DeleteIcon = styled(IconDelete)`
    color: rgba(0,0,0,0.54);

    &:hover {
        color: #bf4065;
    }
`;

const MoveUpIcon = styled(IconArrowUpward)`
    color: rgba(0,0,0,0.54);

    &:hover {
        color: #bf4065;
    }
`;

const MoveDownIcon = styled(IconArrowDownward)`
    color: rgba(0,0,0,0.54);

    &:hover {
        color: #bf4065;
    }
`;

export {
    TBody, TBodyTR, TBodyTD, TBodyTDActions,
    CellWrapper, ActionsCellWrapper, LayoutRow, EnabledLabel,
    DetailContentTR, DetailContentTD,
    DetailsContentWrapper, DetailsContentCard,
    EditCard, Form, FieldSet,
    SwitchLabel,
    IconContainer,
    EditIcon, DeleteIcon, MoveUpIcon, MoveDownIcon,
};
