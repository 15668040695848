import styled from 'styled-components';
import { IconDelete } from 'components/icons';


const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    margin: 16px 0 24px; 
    width: 100%;
`;

const InputWrapper = styled.div`
    flex: 1 1;
    margin-right: 16px;
`;

const DeleteIcon = styled(IconDelete)`
    color: rgba(0,0,0,0.54);
    margin: 0 16px 6px 0;

    &:hover {
        color: #bf4065;
    }
`;

export {
    Wrapper, InputWrapper, DeleteIcon,
};
