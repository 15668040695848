import React from 'react';
import createHistory from 'history/createBrowserHistory';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { ConnectedRouter as Router, routerMiddleware } from 'connected-react-router';
import IntlProvider from '../shared/i18n/IntlProvider';
import { configureStore } from '../shared/store';
import routes, { ContextProvider } from '../shared/routes';
import { configureEnvironment, getBrowserEnvironment } from '../shared/environment';

import { ReactReduxFirebaseProvider } from 'react-redux-firebase';


configureEnvironment(getBrowserEnvironment());

const browserHistory = window.browserHistory || createHistory();
const { store } = configureStore({
    initialState: JSON.parse(window.__PRELOADED_STATE__),
    history: browserHistory,
});

// Firebase - https://react-redux-firebase.com/docs/getting_started.html
import * as firebase from 'firebase/app';

import 'firebase/auth';
import firebaseConfig from '../shared/firebaseConfig';
import { createFirestoreInstance } from 'redux-firestore';
firebase.initializeApp(firebaseConfig);
firebase.firestore();


// react-redux-firebase config
const rrfConfig = {
    userProfile: null,
    // userProfile: 'users',
    // useFirestoreForProfile: true
    // userProfile: 'users',
    // useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
    // enableClaims: true // Get custom claims along with the profile
};

const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance // <- needed if using firestore
}

// const store =
//     window.store ||
//     configureStore({
//         initialState: window.__PRELOADED_STATE__,
//         middleware: [routerMiddleware(browserHistory)],
//     });

const context = {};

// const renderApp = context => (
//     <Provider store={store}>
//         <Router history={browserHistory}>
//             <IntlProvider>
//                 <ContextProvider context={context}>{renderRoutes(routes)}</ContextProvider>
//             </IntlProvider>
//         </Router>
//     </Provider>
// );


const renderApp = context => (
    <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
            <Router history={browserHistory}>
                <IntlProvider>
                    <ContextProvider context={context}>{renderRoutes(routes)}</ContextProvider>
                </IntlProvider>
            </Router>
        </ReactReduxFirebaseProvider>
    </Provider>
);

const root = document.getElementById('app');
hydrate(renderApp(context), root);

// hydrate(
//     <Provider store={store}>
//         <Router history={browserHistory}>
//             <IntlProvider>
//                 <App />
//             </IntlProvider>
//         </Router>
//     </Provider>,
//     document.getElementById('app')
// );

// if (process.env.NODE_ENV === 'development') {
//     if (module.hot) {
//         module.hot.accept('../shared/routes.js', () => {
//             /* eslint-disable global-require */
//             require('../shared/routes.js');
//             console.log('context', context)
//             hydrate(renderApp(context), root);
//         });
//     }
//
//     if (!window.store || !window.browserHistory) {
//         window.browserHistory = browserHistory;
//         window.store = store;
//     }
// }


if (process.env.NODE_ENV === 'development') {
    if (module.hot) {
        module.hot.accept();
    }

    module.hot.accept('../shared/routes.js', () => {
        require('../shared/routes.js');
        hydrate(renderApp(context), root);
    });

    if (!window.store || !window.browserHistory) {
        window.browserHistory = browserHistory;
        window.store = store;
    }
}
