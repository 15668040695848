import { call } from 'redux-saga/effects';
import axios from 'axios';

import environment from '../environment';

const responseHandler = fetchPromise => fetchPromise.then(
    response => ({
        response,
    }),
).catch(error => ({
    error,
}));

/**
 * Load Api Data
 */
export const Get = (url, headers) => responseHandler(
    axios({
        method: 'get',
        url,
        timeout: 15000,
        headers,
    }),
);

export function* reader({ ...action }) {
    // Create URL
    const url = environment.ssr
        ? `http://${environment.vars.host}:${environment.vars.port}${action.endpoint}`
        : action.endpoint
    ;

    // Get headers
    const headers = {};

    return yield call(Get, url, headers);
}



/**
 * Update Api Data
 */
export const Put = (url, headers) => responseHandler(
    axios({
        method: 'put',
        url,
        timeout: 15000,
        headers,
    }),
);

export function* updater({ ...action }) {
    // Create URL
    const url = environment.ssr
        ? `http://${environment.vars.host}:${environment.vars.port}${action.endpoint}`
        : action.endpoint
    ;

    // Get headers
    const headers = {};

    return yield call(Put, url, headers);
}


