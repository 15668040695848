import styled, { css } from 'styled-components';

import { Link as RouterLink } from 'react-router-dom';


const variants = {
    dark: css`
        color: ${({ theme }) => theme.palette.linkDark};
    `,
    accent: css`
        color: ${({ theme }) => theme.palette.accent};
    `,
};

const baseLinkStyle = css`
    /* Default link styling */
    color: ${({ theme }) => theme.palette.link};
    text-decoration: ${({ isUnderlined }) => (isUnderlined ? 'underline' : 'none')};
    cursor: pointer;

    &:hover:enabled {
        text-decoration: underline;
    }

    /* Override default with styling for the variant */
    ${({ variant }) => variants[variant]}
`;

const LinkStyled = styled(RouterLink)`
    ${baseLinkStyle}
`;

export { LinkStyled as default, baseLinkStyle };
