/**
 * Get the state of preloaded
 * @param state
 * @param pageId
 * @returns {*}
 */
export const getPreloaded = (state, pageId) => state.page[pageId] && Boolean(state.page[pageId].preloaded);

/**
 * Get query parameters
 * @param state
 * @param pageId
 * @returns {*|{}}
 */
export const getSearch = (state, pageId) =>
    (state.page[pageId] &&
        state.page[pageId].location && {
        ...state.page[pageId].location.search,
    }) ||
    {};

/**
 * Get url parameters
 * @param state
 * @param pageId
 * @returns {*|{}}
 */
export const getParams = (state, pageId) =>
    (state.page[pageId] &&
        state.page[pageId].location && {
        ...state.page[pageId].location.params,
    }) ||
    {};

/**
 * Get error
 * @param state
 * @param pageId
 * @returns {*}
 */
export const getError = (state, pageId) => state.page[pageId] && state.page[pageId].error;
