import styled from 'styled-components';


const ActionsList = styled.div`
    margin-top: 24px;
    max-width: 100%;
    display: flex;
    align-content: center;
    flex-direction: row;
    justify-content: flex-end;
`;


export { ActionsList };
