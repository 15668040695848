import styled, { css } from 'styled-components';

const Header = styled.header``;

const Main = styled.main`
    position: relative;
`;

const Page = styled.div`
    position: absolute;
    width: 100%;
`;

export { Main, Page };
