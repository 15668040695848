import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${({ size, theme }) => theme.fontSizes[size]};
    width: 1em;
    height: 1em;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 24px;

    svg {
        fill: ${({ theme, palette }) => (theme.palette[palette] ? theme.palette[palette] : theme.palette.icon)};
        width: 100%;
        height: 100%;
    }
`;

type Props = {
    title: string,
};

export default Component => ({ title, ...rest }: Props) => (
    <IconWrapper {...rest}>
        <Component title={title} />
    </IconWrapper>
);
