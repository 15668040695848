

import styled from 'styled-components';


const Wrapper = styled.div`
    justify-content: center;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    flex-wrap: wrap;
    margin-top: ${({ noMargin }) => noMargin ? '0px' : '48px'};
    max-width: 1280px;
    padding: 0 24px;
`;

const Container = styled.div`
    max-width: 936px;
    margin: 0 0 24px;
    width: 100%;
    border-radius: 8px;
    color: rgba(0,0,0,0.87);
    background-color: rgb(255,255,255);
    box-shadow: ${({ noShadow }) => noShadow ? 'none' : '0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);'};
    display: flex;
    flex-direction: column;
`;

export { Wrapper, Container };