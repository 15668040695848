import React from 'react';
import { translate } from 'react-i18next';

import Link from 'components/Link';

import {
    WrapperStyled,
    PageSectionStyled,
    ContainerStyled,
    TitleStyled,
    BodyStyled,
    UlStyled,
    LiStyled,
    IconArrowRightStyled,
    ImageWrapperStyled,
    ImageStyled,
} from './Error.style';
import { type Props } from './Error.flow';
import { ERROR_CODES, AVAILABLE_LINKS } from './Error.constants';

const Error = ({ statusCode = ERROR_CODES.ERROR_500, t }: Props) => {
    const titleKey = `components_error_${statusCode}_title`;
    const bodyKey = `components_error_${statusCode}_body`;

    const links = AVAILABLE_LINKS[statusCode];

    return (
        <WrapperStyled>
            <PageSectionStyled>
                <ContainerStyled>
                    <TitleStyled>{t(titleKey)}</TitleStyled>
                    <BodyStyled>{t(bodyKey)}</BodyStyled>
                    <UlStyled>
                        {links &&
                            links.map(link => (
                                <LiStyled key={link.url}>
                                    <Link to={link.url}>{t(link.i18nKey)}</Link>
                                    <IconArrowRightStyled size="small" />
                                </LiStyled>
                            ))}
                    </UlStyled>
                </ContainerStyled>
            </PageSectionStyled>
        </WrapperStyled>
    );
};

Error.statusCodes = ERROR_CODES;

export default translate()(Error);
