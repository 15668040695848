import { IconComponent as IconAddCircle } from './add_circle-24px.svg';
import { IconComponent as IconArrowRight } from './arrow_right_alt-24px.svg';
import { IconComponent as IconDelete } from './delete-24px.svg';
import { IconComponent as IconVerticalSplit } from './vertical_split-24px.svg';
import { IconComponent as IconArrowDownward } from './arrow_downward-24px.svg';
import { IconComponent as IconArrowUpward } from './arrow_upward-24px.svg';

const iconNameMap = {
    'add-circle': IconAddCircle,
    'arrow-right': IconArrowRight,
    'delete': IconDelete,
    'vertical-split': IconVerticalSplit,
    'arrow-downward': IconArrowDownward,
    'arrow-upward': IconArrowUpward,
};

export {
    IconAddCircle,
    IconArrowRight,
    IconDelete,
    IconVerticalSplit,
    IconArrowDownward,
    IconArrowUpward,
};
