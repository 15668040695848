import { call, put, takeEvery, select } from 'redux-saga/effects';

import { updater } from './common';

const updateUser = function* updateUser(action) {
    const data = yield call(updater, {
        endpoint: `/api/user/${action.id}`,
    });
};

export default function* () {
    yield takeEvery('UPDATE_USER', updateUser);
}
