
import React, { Fragment, useState } from 'react';

import {
    Wrapper, InputWrapper, DeleteIcon,
} from './Fact.style';

import { InputLabel, InputField } from '../Common/Input.style';


const Fact = ({ index, name, altname, value, onChange, onDelete }) => {
    return (
        <Wrapper>
            <InputWrapper>
                <InputLabel>Name</InputLabel>
                <InputField
                    type="text"
                    name="name"
                    autocomplete="off"
                    spellcheck="false"
                    value={name}
                    onChange={(event) => onChange(event, index, 'name')}
                />
            </InputWrapper>
            <InputWrapper>
                <InputLabel>Alt name</InputLabel>
                <InputField
                    type="text"
                    name="altname"
                    autocomplete="off"
                    spellcheck="false"
                    value={altname}
                    onChange={(event) => onChange(event, index, 'altname')}
                />
            </InputWrapper>
            <InputWrapper>
                <InputLabel>Value</InputLabel>
                <InputField
                    type="text"
                    name="name"
                    autocomplete="off"
                    spellcheck="false"
                    value={value}
                    onChange={(event) => onChange(event, index, 'value')}
                />
            </InputWrapper>
            <DeleteIcon onClick={() => onDelete(index)} />
        </Wrapper>
    );
}

export default Fact;
